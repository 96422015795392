import React from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
  CssBaseline,
} from '@mui/material'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

const theme = createTheme({
  ...adaptV4Theme({
    palette: {
      neutral: {
        gray: '#7676A4',
        mediumGray: '#9494BC',
        lightGray: '#E1E1F1',
        secondaryBackground: '#F2F2F9',
      },
      common: {
        black: '#262626',
      },
      primary: {
        main: '#FC642D',
      },
      secondary: {
        main: '#7B3BA0',
      },
      success: {
        main: '#3AB47E',
      },
      info: {
        main: '#509FF4',
      },
      warning: {
        main: '#F9D724',
      },
      error: {
        main: '#FF4D4D',
      },
      background: {
        default: '#fff',
      },
      text: {
        primary: '#262626',
      },
    },
    app: {
      green: '#C5E9C7',
      yellow: '#F9D99E',
      purple: '#DAC2E8',
      primaryGradient:
        'linear-gradient(109.62deg, #FC642D 16.67%, #FFC371 108.83%)',
      secondaryGradient:
        'linear-gradient(130.6deg, #172368 -42.4%, #E249AF 116.58%)',
    },
  }),
  typography: {
    fontFamily: [
      'Lexend',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    t1: {
      fontSize: '8rem',
      fontWeight: 900,
      lineHeight: 1.25,
    },
    t2: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.25,
      textTransform: 'uppercase',
    },
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 400,
      lineHeight: 1.34,
      fontFamily: 'Covered By Your Grace',
    },
    h4: { fontSize: '1.25rem', fontWeight: 600, lineHeight: 1.25 },
    h5: { fontSize: '1rem', fontWeight: 600, lineHeight: 1.25 },
    h6: { fontSize: '0.875rem', fontWeight: 600, lineHeight: 1.25 },
    body2: { fontSize: '1.25rem', fontWeight: 300, lineHeight: 1.25 },
    body3: { fontWeight: 300, fontSize: '1rem', lineHeight: 1.25 },
    body4: { fontWeight: 300, fontSize: '0.875rem', lineHeight: 1.25 },
    button: {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: 1.22,
      textTransform: 'none',
    },
    caption1: { fontWeight: 600, fontSize: '0.75rem', lineHeight: 1.25 },
    caption2: { fontWeight: 600, fontSize: '0.625rem', lineHeight: 1.25 },
    body1: {},
    caption: {},
    subtitle2: {},
    overline: {},
  },
  mixins: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, h1, h2, h3, p, ul': { margin: 0, padding: 0 },
        'html, body, #root': { width: '100%', height: '100%' },
        button: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: { height: 8, borderRadius: 5 },
        bar: { borderRadius: 5 },
      },
    },
    MuiTab: { styleOverrides: { root: { opacity: 1 } } },
    MuiRadio: {
      defaultProps: { color: 'secondary', sx: { color: 'secondary.main' } },
    },
    MuiButton: {
      defaultProps: { color: 'secondary', disableElevation: true },
      styleOverrides: {
        sizeSmall: ({ theme }) => ({
          fontSize: '1rem',
          [theme.breakpoints.down('md')]: {
            fontSize: '0.875rem',
          },
        }),
        text: ({ ownerState }) => ({
          whiteSpace: 'nowrap',
          padding: ownerState.size === 'large' ? '16px 20px' : undefined,
          '&:hover': {
            background:
              'linear-gradient(109.62deg, #FC642D 16.67%, #FFC371 108.83%);',
            backgroundClip: 'text',
            textFillColor: 'transparent',
          },
        }),
        outlined: ({ ownerState, theme }) => ({
          whiteSpace: 'nowrap',
          transition: 'none',
          padding: ownerState.size === 'large' ? '15px 19px' : '8px 15px',
          background: theme.palette.background.default,
          borderWidth: 2,
          '&:hover': {
            borderColor: 'unset',

            // Reset outlined border
            borderWidth: 0,
            padding: ownerState.size === 'large' ? '17px 21px' : '10px 17px',

            background:
              'linear-gradient(109.62deg, #FC642D 16.67%, #FFC371 108.83%);',
            color: theme.palette.primary.contrastText,
          },
        }),
        contained: ({ ownerState }) => ({
          whiteSpace: 'nowrap',
          transition: 'none',
          padding: ownerState.size === 'large' ? '16px 20px' : '9px 16px',
          '&:hover': {
            borderColor: 'unset',
            background:
              'linear-gradient(109.62deg, #FC642D 16.67%, #FFC371 108.83%);',
          },
        }),
      },
    },
    MuiPaper: {
      defaultProps: { elevation: 0 },
      styleOverrides: { root: { boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.4)' } },
    },
    MuiAppBar: { styleOverrides: { root: { boxShadow: 'none' } } },
    MuiList: {
      styleOverrides: { root: { minWidth: 250 } },
      defaultProps: { dense: true },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            background: theme.palette.neutral.secondaryBackground,
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            background: `${theme.palette.neutral.secondaryBackground} !important`,
          },
        }),
      },
    },
    MuiLink: {
      defaultProps: { underline: 'hover', color: 'secondary.main' },
    },
    MuiButtonBase: {
      defaultProps: { disableRipple: true, disableTouchRipple: true },
    },
    MuiSwitch: { defaultProps: { disableFocusRipple: true } },
  },
})

/**
 * Responsive type sizes
 */
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
  },
}
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.4rem',
  },
}
theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
  },
}
theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}
theme.typography.button = {
  ...theme.typography.button,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}
export const ThemeProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StyledComponentsThemeProvider theme={theme}>
          {children}
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
